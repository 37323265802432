import { defineAsyncComponent } from "vue";
export default [
  {
    path: "/site/article",
    name: "site-article",
    title: "Статьи Ultimatum Boxing",
    component: () => import("../template/pages/templateCollection.vue"),
    meta: {
      infocollection: {
        name: "Статьи",
        item: defineAsyncComponent(() =>
          import("@/components/site/article/item.vue")
        ),
      },
      collection: "article",
      populate: [],
      filter: {
        object: ["name"],
        text: "Найти по названию статьи",
        placeholder: "введите название статьи",
        menufilter: [
          { name: "Все", key: "status", action: [true, false] },
          { name: "Активные", key: "status", action: true },
          { name: "Неактивные", key: "status", action: false },
        ],
      },
    },
  },
  {
    path: "/site/article/:id",
    name: "site-article-id",
    title: "Статьи Ultimatum Boxing",
    component: () => import("../template/pages/templatePagesId.vue"),
    meta: {
      collection: "article",
      populate: [{path:'client',select:['firstname','lastname']}],
      template: {
        main: [
          "Title",
          "Images",
          "Editor",
          "Tagsblock",
          "Addarticle",
          "Seo",
          "Deletedocument",
        ],
        sidebar: ["Status","Authorblog", "Categoryblog","Channel"],
      },
    },
  },

  {
    path: "/site/vacancy",
    name: "site-vacancy",
    title: "Вакансии Ultimatum Boxing",
    component: () => import("../template/pages/templateCollection.vue"),
    meta: {
      infocollection: {
        name: "Статьи",
        item: defineAsyncComponent(() =>
          import("@/components/site/vacancy/item.vue")
        ),
      },
      collection: "vacancy",
      populate: [],
      filter: {
        object: ["name"],
        text: "Найти по названию статьи",
        placeholder: "введите название статьи",
        menufilter: [
          { name: "Все", key: "status", action: [true, false] },
          { name: "Активные", key: "status", action: true },
          { name: "Неактивные", key: "status", action: false },
        ],
      },
    },
  },
  {
    path: "/site/vacancy/:id",
    name: "site-vacancy-id",
    title: "Вакансии Ultimatum Boxing",
    component: () => import("../template/pages/templatePagesId.vue"),
    meta: {
      collection: "vacancy",
      template: {
        main: [
          "Title",
          "Images",
          "Editor",
          "Email",
          "Seo",
          "Deletedocument",
        ],
        sidebar: ["Status", "Categoryvacancy","ViewStatick"],
      },
    },
  },
  {
    path: "/site/article/categoryblog",
    name: "site-article-category",
    title: "Категории блога Ultimatum Boxing",
    component: () => import("../template/pages/templateCollection.vue"),
    meta: {
      infocollection: {
        name: "Категории блога",
        item: defineAsyncComponent(() =>
          import("@/components/site/categoryblog/item.vue")
        ),
      },
      collection: "categoryblog",
      populate: [],
      filter: {
        object: ["name"],
        text: "Найти по названию категории",
        placeholder: "введите название категории",
        menufilter: [
          { name: "Все", key: "status", action: [true, false] },
          { name: "Активные", key: "status", action: true },
          { name: "Неактивные", key: "status", action: false },
        ],
      },
    },
  },
  {
    path: "/site/article/categoryblog/:id",                                                                                                                                                             
    name: "site-article-category-id",
    title: "Категория Ultimatum Boxing",
    component: () => import("../template/pages/templatePagesId.vue"),
    meta: {
      collection: "categoryblog",
      populate: [],
      template: {
        main: [                                                                                                                                                                 
          "Title",
          "Images",
          "Editor",
          "Seo",
          "Deletedocument",
        ],
        sidebar: ["Sort","Channel"],
      },
    },
  },
  {
    path: "/site/vacancy/categoryvacancy",
    name: "site-vacancy-category",
    title: "Категории вакансий Ultimatum Boxing",
    component: () => import("../template/pages/templateCollection.vue"),
    meta: {
      infocollection: {
        name: "Категории вакансий",
        item: defineAsyncComponent(() =>
          import("@/components/site/categoryvacancy/item.vue")
        ),
      },
      collection: "categoryvacancy",
      populate: [],
      filter: {
        object: ["name"],
        text: "Найти по названию категории",
        placeholder: "введите название категории",
        menufilter: [
          { name: "Все", key: "status", action: [true, false] },
          { name: "Активные", key: "status", action: true },
          { name: "Неактивные", key: "status", action: false },
        ],
      },
    },
  },
  {
    path: "/site/vacancy/categoryvacancy/:id",                                                                                                                                                             
    name: "site-vacancy-category-id",
    title: "Категория Ultimatum Boxing",
    component: () => import("../template/pages/templatePagesId.vue"),
    meta: {
      collection: "categoryvacancy",
      populate: [],
      template: {
        main: [                                                                                                                                                                 
          "Title",
          "Images",
          "Editor",
          "Seo",
          "Deletedocument",
        ],
        sidebar: ["Sort"],
      },
    },
  },
  {
    path: "/site/page",
    name: "site-page",
    title: "Cтраницы Ultimatum Boxing",
    component: () => import("../template/pages/templateCollection.vue"),
    meta: {
      infocollection: {
        name: "Страницы",
        item: defineAsyncComponent(() =>
          import("@/components/site/page/item.vue")
        ),
      },
      collection: "page",
      populate: [],
      filter: {
        object: ["name"],
        text: "Найти по названию страницы",
        placeholder: "введите название страницы",
        menufilter: [
          { name: "Все", key: "status", action: [true, false] },
          { name: "Активные", key: "status", action: true },
          { name: "Неактивные", key: "status", action: false },
        ],
      },
    },
  },
  {
    path: "/site/page/:id",
    name: "site-page-id",
    title: "Статьи Ultimatum Boxing",
    component: () => import("../template/pages/templatePagesId.vue"),
    meta: {
      collection: "page",
      template: {
        main: ["Title", "Editor", "Seo", "Deletedocument"],
        sidebar: ["Status","Channel"],
      },
    },
  },
  {
    path: "/site/comment",
    name: "site-comment",
    title: "Комментарии Ultimatum Boxing",
    component: () => import("../template/pages/templateCollection.vue"),
    meta: {
      infocollection: {
        name: "Комментарии",
        item: defineAsyncComponent(() =>
          import("@/components/site/comment/item.vue")
        ),
      },
      collection: "comment",
      sort:{updatedAt:-1},
      populate: [
        {path:'club',select:['name']},
        {path:'model',select:['name']},
        {path:'history',select:['name']},
        {path:'article',select:[]}
      ],
      filter: {},
    },
  },
  {
    path: "/site/history",
    name: "site-history",
    title: "Истории Ultimatum Boxing",
    component: () => import("../template/pages/templateCollection.vue"),
    meta: {
      infocollection: {
        name: "Истории",
        item: defineAsyncComponent(() =>
          import("@/components/site/history/item.vue")
        ),
      },
      collection: "history",
      populate: [],
      filter: {
        object: ["name"],
        text: "Найти по названию статьи",
        placeholder: "введите название статьи",
        menufilter: [
          { name: "Все", key: "status", action: [true, false] },
          { name: "Активные", key: "status", action: true },
          { name: "Неактивные", key: "status", action: false },
        ],
      },
    },
  },
  {
    path: "/site/history/:id",
    name: "site-history-id",
    title: "Истории Ultimatum Boxing",
    component: () => import("../template/pages/templatePagesId.vue"),
    meta: {
      collection: "history",
      populate: [{path:'client',select:['firstname','lastname']}],
      template: {
        main: [
          "Title",
          "Images",
          "Editor",
          "Seo",
          "Deletedocument",
        ],
        sidebar: ["Status","Authorblog","Channel"],
      },
    },
  }
];
