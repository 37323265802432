import { defineAsyncComponent } from "vue";
export default [
  {
    path: "/client/client",
    name: "client-client",
    title: "Клиенты Ultimatum Boxing",
    component: () => import("../template/pages/templateCollection.vue"),
    meta: {
      infocollection: {
        name: "Клиенты",
        item: defineAsyncComponent(() =>
          import("@/components/client/client/item.vue")
        ),
      },
      collection: "client",
      populate: [],
      filter: {
        object: ["lastname", "phone","email","webpush"],
        text: "Поиск клиента",
        placeholder: "Введите номер телефона или фамилию клиента",
        menufilter: [
          { name: "Все", key: "status", action: [true, false] },
          { name: "Активные", key: "status", action: true },
          { name: "Неактивные", key: "status", action: false },
        ],
      },
    },
  },
  {
    path: "/client/client/:id",
    name: "client-client-id",
    title: "Клиенты Ultimatum Boxing",
    component: () => import("../template/pages/templatePagesId.vue"),
    meta: {
      collection: "client",
      populate: ["equipment.product"],
      template: {
        main: ["Orderinfo", "Userreview"],
        sidebar: ["Infouser"],
      },
    },
  },
];
