<template>
  <div class="sidebar" :class="{nosi:viewMen}">
    <div class="menu">
      <div class="listmenu" v-for="(m, index) of menu" :key="'menu' + index">
        <div
          class="oneblock"
          @click="
            m.children
              ? viewmenu == index
                ? (viewmenu = null)
                : (viewmenu = index)
              : router.push({ name: m.path })
          "
          :class="{ activityblock: viewmenu == index }"
        >
          <div class="name">{{ m.name }}</div>
          <div v-if="m.children" class="tochka">•</div>
         
        </div>

        <Transition name="slimeleft">
          <div class="podmenu" v-if="viewmenu == index">
            <div
              class="onepodpunct"
              v-for="(c, i) of m.children"
              @click="
                c.children
                  ? viewpodmenu == i
                    ? (viewpodmenu = null)
                    : (viewpodmenu = i)
                  : router.push({ name: c.path })
              "
              :class="{
                activitymenu: router.currentRoute.value.name == c.path,
              }"
            >
              {{ c.name }}
              <svg
                :class="{ rotate: viewpodmenu == i }"
                v-if="c.children"
                width="7"
                height="13"
                viewBox="0 0 7 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M4.58579 6.01318L0.292893 1.72029C-0.0976311 1.32977 -0.0976311 0.696601 0.292893 0.306077C0.683418 -0.0844469 1.31658 -0.0844469 1.70711 0.306077L6.70711 5.30608C7.09763 5.6966 7.09763 6.32977 6.70711 6.72029L1.70711 11.7203C1.31658 12.1108 0.683418 12.1108 0.292893 11.7203C-0.0976311 11.3298 -0.0976311 10.6966 0.292893 10.3061L4.58579 6.01318Z"
                  fill="#8A92A6"
                />
              </svg>

              <Transition name="slimeleft">
                <div class="podmenu threelevel" v-if="viewpodmenu == i">
                  <div
                    class="onepodpunct"
                    v-for="(o, i) of c.children"
                    @click="router.push({ name: o.path })"
                    :class="{
                      activitymenu: router.currentRoute.value.name == o.path,
                    }"
                  >
                    {{ o.name }}
                  </div>
                </div>
              </Transition>
            </div>
          </div>
        </Transition>
      </div>
    </div>
  </div>
</template>
<script setup>
import { useStore } from "vuex";
import { ref } from "vue";
import { useRouter } from "vue-router";
import {viewMen} from "@/utils/general"
    const viewmenu = ref(null);
    const viewpodmenu = ref(null);
    const router = useRouter();
    const menu = useStore().getters["auth/menu"];

</script>
<style scoped>
.sidebar {
  width: 170px;
    height: 100vh;
    background: var(--white);
    padding: 10px;
    position: sticky;
    top: 80px;
    overflow: auto;
    border-radius: 10px;
    margin-top: 20px;
    margin-left: 10px;
    box-shadow: 0 20px 27px rgba(0, 0, 0, .05);
    transition: 500ms;
  
}
.nosi{
  transform: translateX(-100%);
  opacity: 0;
}
.oneblock,
.onepodpunct {
  cursor: pointer;
  color: var(--blue);
  padding: 4px 16px 4px 19px;
  width: calc(100% - 40px);
  font-weight: 100;
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  transition: 200ms;
  border-radius: 5px;
  font-size: 14px;
}
.onepodpunct {
  padding: 5px 15px 5px 35px;
  width: calc(100% - 50px);
  font-size: 14px;
}
.oneblock:hover {
  background: var(--blue);
  color: var(--white);
}

.tochka{
  font-weight: 400;
    transform: translateX(10px);
    font-size: 16px;
}

.menu,
.podmenu {
  display: grid;
  gap: 5px;
}

.oneblock {

}

.activityblock {
  opacity: 1;
}

.activitymenu {
  color: var(--green);
}

.threelevel {
  margin-top: 10px;
}

.threelevel .onepodpunct {
  padding-left: 10px;
}
</style>
