import { defineAsyncComponent } from "vue";
export default [
  {
    path: "/promocode/promocode",
    name: "promocode-promocode",
    title: "Промокоды Ultimatum Boxing",
    component: () => import("../template/pages/templateCollection.vue"),
    meta: {
      infocollection: {
        name: "Промокоды",
        item: defineAsyncComponent(() =>
          import("@/components/site/promocode/item.vue")
        ),
      },
      collection: "promocode",
      populate: [],
      filter: {
        object: ["name"],
        text: "Найти по промокоду",
        placeholder: "введите промокод",
        menufilter: [
          { name: "Все", key: "status", action: [true, false] },
          { name: "Активные", key: "status", action: true },
          { name: "Неактивные", key: "status", action: false },
        ],
      },
    },
  },
  {
    path: "/promocode/promocode/:id",
    name: "promocode-promocode-id",
    title: "Промокоды Ultimatum Boxing",
    component: () => import("../template/pages/templatePagesId.vue"),
    meta: {
      collection: "promocode",
      populate:[{path:'tmppromocode'}],
      template: {
        main: [
          "PromocodeMenu",
          "Deletedocument"
        ],
        sidebar: ["Status"],
      },
    },
  },
  {
    path: "/promocode/multi",
    name: "promocode-multi",
    title: "Массовая генерация Ultimatum Boxing",
    component: () => import("@/components/site/promocode/multi.vue"),
    meta: {
      collection: "promocode",
    }
  },
  {
    path: "/promocode/template",
    name: "promocode-template",
    title: "Шаблоны Ultimatum Boxing",
    component: () => import("../template/pages/templateCollection.vue"),
    meta: {
      infocollection: {
        name: "Шаблоны промокоды",
        item: defineAsyncComponent(() =>
          import("@/components/site/promocode/itemtmp.vue")
        ),
      },
      collection: "tmppromocode",
      populate: [],
      filter: {
        object: ["name"],
        text: "Найти по названию шаблона",
        placeholder: "введите название",
        menufilter: [
          { name: "Все", key: "status", action: [true, false] },
          { name: "Активные", key: "status", action: true },
          { name: "Неактивные", key: "status", action: false },
        ],
      },
    },
  },
  {
    path: "/promocode/template/:id",
    name: "promocode-template-id",
    title: "Промокоды Ultimatum Boxing",
    component: () => import("../template/pages/templatePagesId.vue"),
    meta: {
      collection: "tmppromocode",
      template: {
        main: [
          "PromocodeMenuTmp",
          "Deletedocument"
        ],
        sidebar: [],
      },
    },
  },
];
