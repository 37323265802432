const general = {
  namespaced: true,
  state: {
    notification: [],
    loading: false,
  },
  getters: {
    loading: (state) => state.loading,
    notification: (state) => state.notification,
  },
  mutations: {
    SET_LOADING(state, data) {
      state.loading = data;
    },
    SET_NOTIFICATION(state, active) {
      var data = {
        id: Math.round(+new Date() / 100),
        text: active.text,
        title: active.title || "Поздравляем!",
        type: active.type || "good",
      };
      state.notification.push(data);

      setTimeout(() => {
        state.notification = state.notification.filter((x) => x.id !== data.id);
      },1500)
    },
    REMOVE_NOTIFICATION(state, active) {
      state.notification = state.notification.filter((x) => x.id !== active);
    },
  },
  actions: {
    loading({ commit }, data) {
      commit("SET_LOADING", data);
    },
    addnotification({ commit }, data) {
      commit("SET_NOTIFICATION", data);
    },
    removenotification({ commit }, data) {
      commit("REMOVE_NOTIFICATION", data);
    },
  },
};
export default general;
