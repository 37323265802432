import { defineAsyncComponent } from "vue";

export default {
  Title: defineAsyncComponent(() => import("@/components/page/general/title")),
  Slugarm: defineAsyncComponent(() => import("@/components/page/general/slugarm")),
  Pointarm: defineAsyncComponent(() => import("@/components/page/general/pointarm")),
  Sort: defineAsyncComponent(() => import("@/components/page/general/sort")),
  Datareli: defineAsyncComponent(() => import("@/components/page/general/datareli")),
  Email: defineAsyncComponent(() => import("@/components/page/general/email")),
  Dopopisanie: defineAsyncComponent(() =>
    import("@/components/page/general/dopopisanie")
  ),
  Additionally: defineAsyncComponent(() =>
    import("@/components/page/general/additionally")
  ),
  Razmeradditionally: defineAsyncComponent(() =>
    import("@/components/page/general/razmeradditionally")
  ),
  Dopinfoblock: defineAsyncComponent(() =>
  import("@/components/page/general/dopinfoblock")
  ),
  BlockProductAdd: defineAsyncComponent(() =>
    import("@/components/page/general/blockproductadd")
  ),
  Colorproduct: defineAsyncComponent(() =>
  import("@/components/page/general/colorproduct")
  ),
  Dimensions: defineAsyncComponent(() =>
  import("@/components/page/general/dimensions")
  ),
  Categoryproductblock: defineAsyncComponent(() =>
    import("@/components/page/general/categoryproductblock")
  ),
  Channel: defineAsyncComponent(() =>
  import("@/components/page/general/channel")
  ),
  Status: defineAsyncComponent(() =>
    import("@/components/page/general/status")
  ),
  Statuscertificat: defineAsyncComponent(() =>
  import("@/components/page/general/Statuscertificat")
  ),
  Bog: defineAsyncComponent(() =>
  import("@/components/page/general/bog")
  ),
  Addarticle: defineAsyncComponent(() =>
  import("@/components/page/general/addarticle")
  ),
  Template: defineAsyncComponent(() =>
    import("@/components/page/general/template")
  ),
  Modeldop: defineAsyncComponent(() =>
    import("@/components/page/general/modeldop")
  ),
  Productdop: defineAsyncComponent(() =>
  import("@/components/page/general/productdop")
  ),
  Productartikul: defineAsyncComponent(() =>
  import("@/components/page/general/prartikuls")
  ),

  Category: defineAsyncComponent(() =>
    import("@/components/page/general/category")
  ),
  Сhildrencategory: defineAsyncComponent(() =>
     import("@/components/page/general/childrencategory")
  ),

  Categoryblog: defineAsyncComponent(() =>
    import("@/components/page/general/categoryblog")
  ),
  Authorblog: defineAsyncComponent(() =>
  import("@/components/page/general/authorblog")
  ),
  Categoryvacancy: defineAsyncComponent(() =>
  import("@/components/page/general/categoryvacancy")
 ),
  Technology: defineAsyncComponent(() =>
    import("@/components/page/block/technology")
  ),
  Editor: defineAsyncComponent(() =>
    import("@/components/page/editors/editor")
  ),
  BlockProduct: defineAsyncComponent(() =>
    import("@/components/page/block/product")
  ),
  Articles: defineAsyncComponent(() =>
    import("@/components/page/general/articles")
  ),
  Saleblock: defineAsyncComponent(() =>
    import("@/components/page/block/saleproduct")
  ),
  ViewStatick: defineAsyncComponent(() =>
    import("@/components/page/general/viewstatic")
  ),
  Images: defineAsyncComponent(() =>
    import("@/components/page/general/images")
  ),
  Oblozka: defineAsyncComponent(() =>
  import("@/components/page/general/oblozka")
  ),
  Seo: defineAsyncComponent(() => import("@/components/page/general/seo")),
  Specification: defineAsyncComponent(() =>
    import("@/components/page/block/specification")
  ),
  Avatar: defineAsyncComponent(() =>
    import("@/components/client/block/avatar")
  ),
  Infouser: defineAsyncComponent(() =>
    import("@/components/client/block/infouser")
  ),
  Infouseradmin: defineAsyncComponent(() =>
  import("@/components/adminka/user/infouser")
  ),
  Infouserarm: defineAsyncComponent(() =>
  import("@/components/arm/user/infouser")
  ),
  Menuadmin: defineAsyncComponent(() =>
  import("@/components/adminka/user/menu")
  ),
  Staticsuser: defineAsyncComponent(() =>
    import("@/components/client/block/staticsuser")
  ),
  Orderinfo: defineAsyncComponent(() =>
    import("@/components/client/block/order")
  ),
  Equipclient: defineAsyncComponent(() =>
    import("@/components/client/block/equip")
  ),
  Userreview: defineAsyncComponent(() =>
    import("@/components/client/block/review")
  ),
  Followers: defineAsyncComponent(() =>
    import("@/components/client/block/followers")
  ),
  Deletedocument: defineAsyncComponent(() =>
    import("@/components/general/deletedocument")
  ),
  TypeEducationDocument: defineAsyncComponent(() =>
    import("@/components/page/education/typeeducationdocument")
  ),
  DocumentQuestion: defineAsyncComponent(() =>
    import("@/components/page/education/question")
  ),
  ArrayDocument: defineAsyncComponent(() =>
    import("@/components/page/education/arraydocument")
  ),
  ArrayThemes: defineAsyncComponent(() =>
    import("@/components/page/education/arraythemes")
  ),
  OrderContragent: defineAsyncComponent(() =>
  import("@/components/order/contragent")
  ),
  ClientClub: defineAsyncComponent(() =>
  import("@/components/page/general/clientclub")
  ),
  OrderEditor: defineAsyncComponent(() =>
  import("@/components/order/ordereditor")
  ),
  OrderProduct: defineAsyncComponent(() =>
  import("@/components/order/productadd")
  ),
  OrderDelivery: defineAsyncComponent(() =>
  import("@/components/order/delivery")
  ),
  OrderPayment: defineAsyncComponent(() =>
  import("@/components/order/payment")
  ),
   OrderPaymentReturn: defineAsyncComponent(() =>
    import("@/components/returnorder/payment")
  ),
  OrderStatus: defineAsyncComponent(() =>
    import("@/components/order/status")
    ),
OrderStatusReturn: defineAsyncComponent(() =>
      import("@/components/returnorder/status")
      ),  
  Tagsblock: defineAsyncComponent(() =>
  import("@/components/page/general/tagsblock")
  ),
  Cityclub: defineAsyncComponent(() =>
    import("@/components/page/general/cityclub")
  ),
  Infoclub: defineAsyncComponent(() =>
  import("@/components/page/general/infoclub")
  ),
  Grafik: defineAsyncComponent(() =>
  import("@/components/select/grafik")
  ),
  Selectclient: defineAsyncComponent(() =>
  import("@/components/select/selectclient")
  ),
  Infoselect: defineAsyncComponent(() =>
  import("@/components/select/infoselect")
  ),
   Viewcert: defineAsyncComponent(() =>
    import("@/components/certificates/viewcert")
    ),
     OrderEditorReturn: defineAsyncComponent(() =>
    import("@/components/returnorder/ordereditor")
  ),
    OrderProductReturn: defineAsyncComponent(() =>
    import("@/components/returnorder/productitem")
    ),
     PromocodeMenu: defineAsyncComponent(() =>
      import("@/components/site/promocode/menu")
      ),
         PromocodeMenuTmp: defineAsyncComponent(() =>
        import("@/components/site/promocode/menutmp")
        ),
};
